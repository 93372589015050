<template>
	<div class="add-photo">
		<div class="add-photo__label" v-if="label">{{ label }}</div>
		<div class="add-photo__main">
			<label for="upload-photo" class="add-photo__upload">
				<or-image
					v-if="imageForView"
					:src="imageForView"
					class="add-photo__img"
					width="100"
					height="100"
					alt="profile image"
					decoding="async"
				/>
				<img
					v-else
					src="@/assets/img/svg/broker/skeleton-img.svg"
					alt="profile not image"
					decoding="async"
					width="44"
					height="44"
				/>
			</label>
			<input
				type="file"
				accept=".png, .jpg, .jpeg"
				id="upload-photo"
				@change="selectImage"
				hidden
			/>
			<div class="add-photo__info">
				<div class="add-photo__text">
					<span> {{ $t('web.recommended_size') }}:</span>
					<br />
					240x240 - JPG, PNG
				</div>
				<div class="add-photo__text">
					<span>{{ $t('web.max_size') }}:</span>
					2MB
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: ''
		},
		defaultImage: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			imageForUpload: null
		}
	},
	computed: {
		imageForView() {
			return this.imageForUpload || this.defaultImage
		}
	},
	methods: {
		selectImage(evt) {
			const imageFile = evt.target.files[0]
			if (imageFile.size > 2 * 1024 * 1024) {
				this.$store.commit('showNotif', {
					type: 'error',
					text: this.$t('web.notification_max_image_size')
				})

				return
			}

			this.imageForUpload = URL.createObjectURL(imageFile)
			this.$emit('upload', imageFile)
		}
	}
}
</script>

<style lang="scss">
.add-photo {
	display: flex;
	flex-direction: column;
	gap: 10px;
	&__label {
		color: var(--secondary-1, #808080);
		font-size: 14px;
		font-weight: 600;
		line-height: normal;
	}
	&__main {
		display: flex;
		align-items: center;
		gap: 20px;
	}
	&__upload {
		border-radius: 4px;
		border: 1px dashed var(--opacity-black-10, rgba(0, 0, 0, 0.1));
		background: var(--opacity-black-2, rgba(0, 0, 0, 0.02));
		width: 100px;
		height: 100px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		overflow: hidden;

		.image-component {
			width: 100%;
			height: 100%;
		}

		&:hover {
			img {
				transform: scale(1.1) rotate(3deg);
			}
		}
		& > img {
			transition: all 0.3s ease;
		}
	}
	&__img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
	}
	&__info {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	&__text {
		color: var(--secondary-1, #808080);
		font-size: 12px;
		font-weight: 400;
		line-height: normal;
		span {
			font-size: 14px;
			font-weight: 600;
			margin-bottom: 2px;
		}
	}
}
</style>
